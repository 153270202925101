export const switches = [
  {
    id: 1,
    title: "Бухгалтерия\nПРОФ",
    web: 1400,
    rdp: 2025,
  },
  {
    id: 2,
    title: "Бухгалтерия\nКОРП",
    web: 1855,
    rdp: 2485,
  },
  {
    id: 3,
    title: "Зарплата и Управление персоналом",
    web: 1515,
    rdp: 2140,
  },
  {
    id: 4,
    title: "Управление торговлей",
    web: 1515,
    rdp: 2140,
  },
  {
    id: 5,
    title: "Розница\nПРОФ",
    web: 1400,
    rdp: 2025,
  },
  {
    id: 6,
    title: "Управление нашей фирмой",
    web: 1400,
    rdp: 2025,
  },
  {
    id: 7,
    title: "Комплексная автоматизация",
    web: 2120,
    rdp: 2745,
  },

  {
    id: 8,
    title: "Автосервис",
    web: 1855,
    rdp: 2485,
  },
];
